<template>
<div class="p-3">
  User Media Component
  <user-media
    ref="usermedia"
    :config="{video}"
    @user-update="onUserUpdate"
    />
  <div class="form-inline sub-mt-2 sub-mr-2 mb-2">
    <b-form-checkbox v-model="video">Video</b-form-checkbox>
    <button class="btn btn-primary" @click="$refs.usermedia.createStream();">Create</button>
    <button class="btn btn-primary" @click="$refs.usermedia.destroyStream();">Destroy</button>
    <button class="btn btn-primary" @click="$refs.usermedia.shareScreen();">Share Screen</button>
    <button class="btn btn-primary" @click="$refs.usermedia.stopScreenShare();">Stop Share Screen</button>
    <button class="btn btn-primary" @click="$refs.usermedia.setMuted(true);">Mute</button>
    <button class="btn btn-primary" @click="$refs.usermedia.setMuted(false);">Unmute</button>
  </div>
  <debug-obj label="Test.localUser" :objData="{localUser}"/>
  <video-layout :users="[localUser]" v-if="localUser"/>
</div>
</template>

<script>
import { getLog } from "@/services/log";
let log = getLog('test-user-media');
import userMedia from '../../components/userMedia.vue'
import VideoLayout from '../../components/videoLayout.vue';

export default {
  components: { 
    userMedia,
    VideoLayout,
  },
  data() {
    return {
      video: false,

      localUser: null,
    };
  },
  mounted() {
    log.log("mounted");
    this.$refs.usermedia.createStream();
  },
  methods: {
    onUserUpdate(v) {
      log.log('onUserUpdate', v);
      this.localUser = v; 
    }
  }
}
</script>

<style>

</style>